export default {
  colors: {
    blue: "#abebff",
    grey: "#fadbd4",
    text: "#333",
    black: "#000",
    pink: "#f09",
    white: "#fff",
  },
};
