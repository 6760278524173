import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Mint, { MintProps } from "./Mint";

import Discord from "./images/discord.svg";
import Twitter from "./images/twitter.svg";
import HeroImage from "./images/hero.jpeg";

import theme from "./theme";

const Home = (props: MintProps) => {
  const Background = styled.div`
    padding: 20px;
    box-shadow: inset 0px 0px 0px 20px ${theme.colors.pink};
    background-color: ${theme.colors.blue};
    box-sizing: border-box;
    width: 100%;
  `;
  const Hero = styled.div`
    position: relative;
    box-sizing: border-box;
    padding: 2rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
  `;

  const Body = styled.div`
    padding: 4rem 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.colors.grey};
  `;

  const Heading = styled.h1`
    font-family: "SF Hollywood Hills";
    letter-spacing: 0.05em;
    color: ${theme.colors.pink};
    font-size: 8rem;
    margin: 2rem 0rem 2rem 0rem;
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 5rem;
    }
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  `;

  const Heading2 = styled.h1`
    font-family: "SF Hollywood Hills";
    letter-spacing: 0.05em;
    color: ${theme.colors.pink};
    font-size: 4rem;
    margin: 0rem 0rem 1rem 0rem;
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      font-size: 3rem;
    }
  `;

  const Subheading = styled.p`
    text-align: center;
    color: black;
    line-height: 2rem;
    font-size: 1.6rem;
    color: ${theme.colors.text};
  `;

  const Content = styled.div`
    margin: 2rem 0rem;
    max-width: 1080px;
  `;

  const Socials = styled.div`
    display: flex;
    margin: 0 0 2rem 0;
    align-items: center;
    justify-content: center;
  `;

  const SocialIcon = styled.img`
    height: 3rem;
    padding: 1rem;
    margin: 0rem 1rem;
    cursor: pointer;
  `;

  const Polaroid = styled.div`
    z-index: 10;
    --shadow-color: 194deg 100% 50%;
    border: white 24px solid;
    background-color: white;
    box-shadow: 2px 4px 4px hsl(var(--shadow-color) / 0.2),
      4px 8px 8px hsl(var(--shadow-color) / 0.2),
      8px 16px 16px hsl(var(--shadow-color) / 0.2),
      16px 32px 32px hsl(var(--shadow-color) / 0.2),
      32px 64px 64px hsl(var(--shadow-color) / 0.2);
    transform: rotate(-8deg);
    -webkit-backface-visibility: hidden;
    max-width: 1000px;
    width: 60%;
    @media (min-width: 600px) and (max-width: 1023px) {
      width: 70%;
    }
    @media (max-width: 600px) {
      width: 80%;
    }
  `;

  const PolaroidImage = styled.img`
    width: 100%;
  `;

  const PolaroidTextWrapper = styled.div`
    width: 100%;
    background: white;
  `;

  const PolaroidText = styled.p`
    text-align: center;
    font-family: "Caveat", cursive;
    font-size: 5rem;
    padding: 0rem 0rem 1rem 0rem;
    margin: 0;
    transform: rotate(-5deg);
    @media (min-width: 600px) and (max-width: 1023px) {
      font-size: 4rem;
    }
    @media (max-width: 600px) {
      font-size: 2rem;
    }
  `;

  const Roadmap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `;

  const RoadmapTitle = styled.h3`
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0rem 0rem 0rem 0rem;
  `;

  const FAQDescription = styled.p`
    font-size: 1.2rem;
    line-height: 2rem;
    color: ${theme.colors.text};
    margin-bottom: 1.5rem;
    font-weight: 700;
  `;

  return (
    <Background>
      <Hero>
        <Heading>Solebrities</Heading>
        <Polaroid>
          <PolaroidImage src={HeroImage} />
          <PolaroidTextWrapper>
            <PolaroidText>Launching soon!</PolaroidText>
          </PolaroidTextWrapper>
        </Polaroid>
      </Hero>
      <Body>
        <Content>
          <Subheading>
            Welcome to Solebrities! We're an NFT celebrating the glitz and
            glamor of Hollywood! We’re one of the first female-led Solana
            projects, hoping to bring more diversity into the NFT space.
          </Subheading>
          <Subheading>
            Our art is hand-drawn by members of our team, and takes inspiration
            from pop culture, movies, music, and more!
          </Subheading>
        </Content>
        <Content>
          <Heading2>Our Socials</Heading2>
          <Socials>
            <SocialIcon
              onClick={() =>
                window.open("https://discord.gg/solebrities", "_blank")
              }
              src={Discord}
            />
            <SocialIcon
              onClick={() => {
                window.open("https://twitter.com/SOLebrities", "_blank");
              }}
              src={Twitter}
            />
          </Socials>
        </Content>
        <Content>
          <Roadmap>
            <Heading2>FAQ</Heading2>
            <RoadmapTitle>When does pre-sale start?</RoadmapTitle>
            <FAQDescription>TBD (will be announced in Discord)</FAQDescription>
            <RoadmapTitle>When does minting start?</RoadmapTitle>
            <FAQDescription>TBD (will be announced in Discord)</FAQDescription>
            <RoadmapTitle>How much does each Solebrity cost?</RoadmapTitle>
            <FAQDescription>
              0.3 SOL for pre-sale, 0.5 SOL for the main mint
            </FAQDescription>
            <RoadmapTitle>
              How many Solebrities are there in total?
            </RoadmapTitle>
            <FAQDescription>
              We will be selling 500 Solebrities in the pre-sale & 4500 in the
              main mint. There are a total of 5000 Solebrities in the entire
              collection.
            </FAQDescription>
            <RoadmapTitle>
              Are there any rewards for minting Solebrities?
            </RoadmapTitle>
            <FAQDescription>
              We’re giving away 10% of the mint proceeds to top 100 holders of
              Solebrities!
            </FAQDescription>
            <RoadmapTitle>
              How will the top holders receive their rewards?
            </RoadmapTitle>
            <FAQDescription>
              The reward will directly be sent to your Solana Wallet if you are
              holding a top holder during the snapshot!
            </FAQDescription>
            <RoadmapTitle>What will we do with the royalties?</RoadmapTitle>
            <FAQDescription>
              We’ll only charge 5% royalties, and we’ll return 4% of the
              royalties to ALL Solebrities, while keeping 1% in the trust to
              cover the operating costs.
            </FAQDescription>
          </Roadmap>
        </Content>
      </Body>
    </Background>
  );
};

export default Home;
